import api from 'utils/api';

export async function forgotPassword(data) {
  return await api
    .post('/auth/password/forget', data)
    .then(res => {
      const {
        data: {
          data: { message },
        },
      } = res;
      return message;
    })
    .catch(err => {
      throw err;
    });
}

export async function verifyOtp(data) {
  return await api
    .post('/auth/verify', data)
    .then(res => {
      const {
        data: { data },
      } = res;
      return data;
    })
    .catch(err => {
      throw err;
    });
}

export async function resetPassword(data) {
  return await api
    .post('/auth/password/reset', data)
    .then(res => {
      const {
        data: {
          data: { message },
        },
      } = res;
      return message;
    })
    .catch(err => {
      throw err;
    });
}
