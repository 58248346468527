import { Box, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { snackbarErrorMsg } from "config/constants";
import { isEmpty, toLower } from "lodash";
import { searchByCodeAndDescription } from "models/Code";
import { submitUserDataSource } from "models/DataSource";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import snackbar from "utils/snackbar";

function AddDescription({ open, setOpen, current, fetchDocument }) {
  const { currentUser } = useSelector((state) => state.auth);
  const [code, setCode] = useState({});
  const [codes, setCodes] = useState([]);
  // const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  let debounceTimer; // To implement a debounce for API requests
  const type = currentUser?.user_type[toLower(current?.kind)];

  useEffect(() => {
    if (open && current?.text?.length > 0) {
      fetchCodes(current?.text);
    }
  }, [open, current?.text]);

  const fetchCodes = async (val) => {
    clearTimeout(debounceTimer);
    // Implement a debounce to wait for a brief pause in typing
    if (val.length === 0) {
      setCodes([]);
    } else {
      debounceTimer = setTimeout(() => {
        // Make an API call using the Fetch API
        setLoading(true);
        searchByCodeAndDescription(type, val)
          .then((data) => {
            setCodes(data);
          })
          .catch((error) => {
            snackbar.error(error?.message || "Can't Fetch Search List.");
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 1000); // Adjust the debounce time (e.g., 300 milliseconds) to your preference
    }
  };

  const handleSubmit = async () => {
    try {
      setOpen(false);
      const data = {
        name: currentUser?.user_type[toLower(current?.kind)],
        entityId: code?.code,
      };
      await submitUserDataSource(current?.document_id, current?.id, data);
      await fetchDocument(current?.document_id);
      snackbar.success("Description Created Successfully.");
    } catch (error) {
      snackbar.error(snackbarErrorMsg);
      console.log(error);
    } finally {
      resetForm();
    }
  };

  const resetForm = () => {
    setCode({});
    setCodes([]);
    // setSearchKey("");
  };

  const isDisabled = isEmpty(code) || loading;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        setOpen(false);
        resetForm();
      }}
      aria-labelledby="add-user-first-name"
      aria-describedby="add-user-description"
    >
      <DialogTitle id="add-user-first-name">Add New Description</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Autocomplete
            value={code}
            onChange={(event, newValue) => setCode(newValue)}
            sx={{ my: 2 }}
            id="code"
            options={codes}
            fullWidth
            filterOptions={(e) => e}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Code"
                onKeyUp={(e) => fetchCodes(e?.target?.value)}
              />
            )}
            getOptionLabel={(opt) =>
              opt?.id ? opt?.description : current?.text
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 3, flexShrink: 0 } }}
                {...props}
              >
                <span>
                  <b>({option?.code})</b> - {option?.description} --- #
                  {option?.id}
                </span>
              </Box>
            )}
            clearOnBlur={false}
          />
          {loading && (
            <Box sx={{ mx: 2 }}>
              <CircularProgress size="2rem" />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            resetForm();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isDisabled}
          variant="contained"
        >
          Add Description
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddDescription;
