import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";

import { makeStyles } from "@mui/styles";
import SwipeableTemporaryDrawer from "components/SideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";

// import { useLocation } from "react-router-dom";
import Uploading from "components/Uploading/Uploading";
import { logoutUser } from "redux/features/auth.slice";

import { Logo } from "assets/lottie/svg-component";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    "&&&": {
      backgroundImage:
        "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    },
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Layout({ children, cta, uploading, uploadProgress }) {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  // const location = useLocation();
  const classes = useStyles();

  const {
    auth: { currentUser },
    upload: { progress, isUploading },
  } = useSelector((state) => state);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSideMenuChange = () => {
    setMenuOpen((st) => !st);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{ minHeight: "100%" }}>
      <Dialog open={openDialog} maxWidth="md">
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          <ol>
            <li>
              <b>Data Privacy:</b> The system does not require or request any
              patient-identifiable data or sensitive information to operate. We
              are committed to safeguarding patient data privacy and security.
            </li>
            <li>
              <b>Medical Neutrality:</b> The system is designed to be neutral
              and does not interfere with any medical conditions, diagnosed
              patients, or decisions that impact patient conditions. It operates
              solely with unidentified data for coding purposes.
            </li>
            <li>
              <b>Human Oversight:</b> The system requires human intervention
              from individuals responsible for making the final decisions based
              on the generated results. It is a tool to assist, not replace,
              human expertise.
            </li>
            <li>
              <b>User Eligibility:</b> By using the system, you confirm that you
              are eligible and authorized to use the codes and services
              provided. Your use of the system signifies your agreement with the
              terms outlined above.
            </li>
            <li>
              <b>Liability:</b> While the system is designed to provide accurate
              coding results, it is important to acknowledge that no automated
              system is infallible. The organization disclaims liability for any
              errors or consequences arising from the use of the system.
            </li>
            <li>
              <b>Data Usage:</b> The system does not collect or process any
              patient-identifiable data or sensitive information to operate,
              ensuring that patient information remains secure and private.
            </li>
            <li>
              <b>Compliance:</b> Our system is designed to comply with
              healthcare regulations, and follows best practices to ensure data
              security and compliance as we can.
            </li>
            <li>
              <b>User Responsibilities:</b> Users are responsible for ensuring
              the accuracy of the data provided and making informed decisions
              based on the system's output. The system serves as a tool, and
              ultimate decision-making lies with the user.
            </li>
            <li>
              <b>Contact Information:</b> For any questions, concerns, or to
              report issues related to the system, please reach out to your
              vendor.{" "}
            </li>
            <li>
              <b>Accessibility:</b> This disclaimer is easily accessible to all
              users, ensuring transparency and accountability.
            </li>
            <li>
              <b>Regulatory Review:</b> This disclaimer has been reviewed to
              align with the specific requirements for AI clinical coding
              systems in our jurisdiction.
            </li>
          </ol>
          <p>
            <b>
              Clicking "continue" implies your acceptance of all the terms and
              conditions stated in this comprehensive disclaimer.
            </b>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: "white" }}
            onClick={handleSideMenuChange}
          >
            <MenuIcon />
          </IconButton>

          <Box
            p={1}
            color="#fff"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              ml: 5,
            }}
          >
            <Box clone="true">
              <Logo height="55" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDialogOpen}
              sx={{ backgroundColor: "#bbb", color: "#1d1819" }}
            >
              Open Disclaimer
            </Button>
            <Box
              sx={{
                // p: 1,
                mx: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                fontWeight="bold"
                component="div"
                variant="h6"
                sx={{ color: "#fff", borderBottom: "2px #fff solid" }}
              >
                ( {currentUser?.user_type?.name.toUpperCase()} )
              </Typography>
            </Box>
            <Box sx={{ mr: 5 }}>{cta}</Box>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  sx={{
                    width: "2.6rem",
                    height: "2.6rem",
                    color: "#fff",
                    bgcolor: "#bbb",
                  }}
                >
                  {currentUser?.user?.firstName?.charAt(0)?.toUpperCase()}
                  {currentUser?.user?.lastName?.charAt(0)?.toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={() => dispatch(logoutUser())}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableTemporaryDrawer state={menuOpen} setState={setMenuOpen} />

      <main className={classes.content}>
        {/* {uploading && (
          <LinearProgress variant="determinate" value={uploadProgress} color="secondary" />
        )} */}
        {isUploading && <Uploading progress={progress} />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: { lg: 8, md: 6, sm: 4, xs: 2 },
            py: { lg: 4, md: 3, sm: 2, xs: 1 },
            flex: 1,
          }}
        >
          {children}
        </Box>
      </main>
    </Box>
  );
}

export default Layout;
