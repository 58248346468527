import api from "utils/api";

export const submitUserDataSource = async (documentId, entityId, payload) => {
  return await api
    .post(`/documents/${documentId}/entities/${entityId}/data-sources`, payload)
    .then((res) => {
      const {
        data: {
          data: { dataSource },
        },
      } = res;
      return dataSource;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const deleteUserDataSource = async (documentId, entityId, id) => {
  return await api
    .delete(`/documents/${documentId}/entities/${entityId}/data-sources/${id}`)
    .then((res) => {
      const {
        data: { data },
      } = res;
      return data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
