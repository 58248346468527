import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { getSubmissionsFile } from "models/Validate";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export const Row = ({ row, setOpen }) => {
  const downloadFile = async () => {
    await getSubmissionsFile(row.id, setOpen);
  };

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">{row?.id}</TableCell>
        <TableCell align="center">{row?.SenderID}</TableCell>
        <TableCell align="center">{row?.ReceiverID}</TableCell>
        <TableCell align="center">{row?.RecordCount}</TableCell>
        <TableCell
          align="center"
          sx={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Link to={`${row?.id}`}>
            <Button variant="outlined">Result</Button>
          </Link>
          <Button variant="outlined" onClick={downloadFile}>
            File
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
