import api from "utils/api";

export const searchByCodeAndDescription = async (type, searchKey) => {
  return await api
    .post("/codes/search", { type, searchKey })
    .then((res) => {
      const {
        data: {
          data: { codes },
        },
      } = res;
      return codes;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
