import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Layout from "components/Layout/Layout";
import { getUserSubmissions } from "models/Validate";
import { useEffect, useState } from "react";
import { Row } from "./components/Row";

export const PreviousUploads = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    setData(await getUserSubmissions());
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <Box
        sx={{
          bgcolor: "background.paper",
          display: "flex",
          p: 3,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={1500}
          onClose={() => setOpen(false)}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            File Doesn't exist!
          </Alert>
        </Snackbar>
        <LoadingButton
          sx={{ my: 5, width: "80%" }}
          loading={loading}
          loadingPosition="center"
          startIcon={<RefreshIcon />}
          variant="contained"
          size="large"
          onClick={() => fetchData()}
        >
          Refresh
        </LoadingButton>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Sender</TableCell>
                <TableCell align="center">Receiver</TableCell>
                <TableCell align="center">Record Count</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, idx) => (
                <Row key={idx} row={row} setOpen={setOpen} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
};
