import api from "utils/api";

export const getUserDocuments = async () => {
  return await api
    .get("/documents")
    .then((res) => {
      const {
        data: {
          data: { documents },
        },
      } = res;
      return documents;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const acceptConsent = async () => {
  return await api
    .post("/users/concent")
    .then((res) => {})
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getUserManualDocuments = async () => {
  return await api
    .get("/documents/manual")
    .then((res) => {
      const {
        data: {
          data: { documents },
        },
      } = res;
      return documents;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const submitUserDocuments = async (payload) => {
  return await api
    .post("/documents", payload)
    .then((res) => {
      const {
        data: {
          data: { documentId },
        },
      } = res;
      return documentId;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const submitUserManualDocument = async (payload) => {
  return await api
    .post("/documents/manual", payload)
    .then((res) => {
      const {
        data: {
          data: { documentId },
        },
      } = res;
      return documentId;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getUserDocument = async (id) => {
  return await api
    .get(`/documents/${id}`)
    .then((res) => {
      const {
        data: { data },
      } = res;
      return data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getUserManualDocument = async (id) => {
  return await api
    .get(`/documents/${id}/manual`)
    .then((res) => {
      const {
        data: { data },
      } = res;
      return data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
