import React from "react";
import Box from "@mui/material/Box";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "components/Login/Login";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";

function AuthPage() {
  const { currentUser } = useSelector((state) => state.auth);

  const loginFormHeader = (
    <>
      <Typography variant="h4" gutterBottom align="center">
        Sign in
      </Typography>
    </>
  );

  if (!isEmpty(currentUser)) {
    return <Navigate to={{ pathname: "/" }} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Login formHeader={loginFormHeader} />
    </Box>
  );
}

export default AuthPage;
