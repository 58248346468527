import { Divider } from "@mui/material";

export function ValidationErrorsList(errorMessages) {
  return (
    <ul>
      {errorMessages.map(
        (claim, index) =>
          claim.errorMessages.length > 0 && (
            <div
              key={index}
              style={{ marginBottom: "10px", paddingBottom: "10px" }}
            >
              <li style={{ marginBottom: "3px" }}>{`Claim: ${claim.claim}`}</li>
              <ul>
                {claim.errorMessages.map((error, errorIndex) => (
                  <div key={errorIndex}>
                    <li style={{ color: "red" }}>
                      {error.id
                        ? `Code: ${error.id}, ${error.message}`
                        : `Code: ${error.message}`}
                    </li>
                  </div>
                ))}
              </ul>
              {index < errorMessages.length - 1 && <Divider />}
            </div>
          )
      )}
    </ul>
  );
}
