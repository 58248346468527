import AddIcon from "@mui/icons-material/AddCircle";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button, FormControlLabel, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import AddDescription from "components/AddDescription/AddDescription";
import AddRow from "components/AddRow/AddRow";
import WarningDialog from "components/WarningDialog/WarningDialog";
import { deleteUserDataSource } from "models/DataSource";
import { getUserDocument } from "models/Document";
import { deleteUserEntity, setEntityPrincipal } from "models/Entity";
import { validateSelectedCodes } from "models/Validate";
import * as React from "react";
import snackbar from "utils/snackbar";
import { useSelector } from "react-redux";

const PopoverCard = ({ row }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Typography
        sx={{
          color:
            row?.kind === "Diagnosis"
              ? "green"
              : row?.kind === "Procedure"
              ? "blue"
              : row?.kind === "Medication"
              ? "orange"
              : "black",
          fontWeight: "bold",
          cursor: "pointer",
          borderBottom: row?.negative
            ? "3px solid red"
            : `2px dashed ${
                row?.kind === "Diagnosis"
                  ? "green"
                  : row?.kind === "Procedure"
                  ? "blue"
                  : row?.kind === "Medication"
                  ? "orange"
                  : "black"
              }`,
          mx: 0.5,
        }}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        component="span"
      >
        {row?.text}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Card sx={{ minWidth: "500px" }}>
            <CardContent sx={{ mx: 3 }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ textAlign: "center" }}
              >
                {row?.text}
                <br />
                <Typography variant="body1" color="text.secondary">
                  score: {row?.confidenceScore}
                </Typography>
              </Typography>
              <Divider />
              {row?.dataSources?.map((dataSource, idx) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    key={Math.floor(Math.random() * 100000 + (idx + 1))}
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      mb: 2,
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight="bold"
                      sx={{ width: "20%" }}
                    >
                      {dataSource?.entityId}
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        width: "80%",
                      }}
                    >
                      <small style={{ marginLeft: 2 }}>
                        {dataSource?.id ? "" : "(Suggestion)"}
                      </small>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontWeight="bold"
                      >
                        {dataSource?.description}
                      </Typography>
                    </Box>
                  </Box>
                  {idx < row?.dataSources?.length - 1 && <Divider />}

                  {dataSource?.sources?.map((source, idx) => (
                    <Box
                      key={Math.floor(Math.random() * 100000 + (idx + 1))}
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        mb: 2,
                        width: "80%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ width: "20%" }}
                      >
                        {source?.code}
                      </Typography>
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          width: "80%",
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {source?.description}
                        </Typography>
                      </Box>
                      {idx < dataSource?.sources?.length - 1 && <Divider />}
                    </Box>
                  ))}
                </Box>
              ))}
            </CardContent>
          </Card>
        </Popover>
      </Typography>
    </>
  );
};

const MainListItem = ({
  row,
  checked,
  handleToggle,
  labelId,
  setAddRowOpen,
  setAddDescriptionOpen,
  setKind,
  setSelectedRow,
  documentId,
  fetchDocument,
  errorMessages,
  currentUser,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpenAddRow = () => {
    setAddRowOpen(true);
    setKind(row?.kind);
  };

  return (
    <>
      <ListItem
        sx={{ p: 0 }}
        secondaryAction={
          <ListItemButton role={undefined} sx={{ p: 0 }}>
            <IconButton
              edge="start"
              aria-label="expo"
              sx={{ m: 0.5, p: 0 }}
              onClick={handleOpenAddRow}
            >
              <AddIcon color="primary" sx={{ fontSize: "1.8rem" }} />
            </IconButton>
          </ListItemButton>
        }
      >
        <ListItemButton role={undefined} dense sx={{ p: 0 }}>
          <IconButton
            edge="end"
            aria-label={open ? "hide" : "show"}
            sx={{ mr: 0.1, p: 0 }}
            onClick={handleClick}
          >
            {open ? (
              <ExpandLess sx={{ fontSize: "1.8rem" }} />
            ) : (
              <ExpandMore sx={{ fontSize: "1.8rem" }} />
            )}
          </IconButton>
          <ListItemText
            sx={{ mr: 0 }}
            id={labelId}
            primary={
              <Typography
                fontSize="large"
                fontWeight="bold"
                variant="body1"
                component="div"
              >
                {`${row?.Text} (${row?.childs?.length || 0})`}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ mx: "5%" }} component="div">
          {row?.childs?.map((value, idx) => {
            const labelId = `checkbox-list-label-${value.Id}`;
            return (
              <RowListItem
                key={`row-${idx}`}
                row={value}
                checked={checked}
                documentId={documentId}
                handleToggle={handleToggle}
                labelId={labelId}
                fetchDocument={fetchDocument}
                setAddDescriptionOpen={setAddDescriptionOpen}
                setSelectedRow={setSelectedRow}
                errorMessages={errorMessages}
                currentUser={currentUser}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const DescriptionSubListItem = ({
  row,
  checked,
  handleToggle,
  labelId,
  current,
  parent,
  errorMessages,
}) => {
  const [validationMessages, setValidationMessages] = React.useState([]);
  const id = `${current?.entity_id}@${current?.id ?? current?.entityId}@${
    row?.code
  }`;
  React.useEffect(() => {
    if (errorMessages.length > 0) {
      setValidationMessages(errorMessages.filter((e) => e?.id === id));
    }
  }, [errorMessages, id]);

  return (
    <ListItem sx={{ p: 0 }}>
      <ListItemButton role={undefined} dense>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.indexOf(id) !== -1}
            tabIndex={-1}
            disableRipple
            onClick={handleToggle(id, {
              code: row?.code,
              category: parent?.category,
              kind: parent?.kind,
              suggest: true,
            })}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </ListItemIcon>

        <ListItemText
          id={labelId}
          sx={{ p: 0 }}
          primary={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ width: "20%" }}
                fontSize="large"
                variant="body1"
                component="div"
              >
                {row?.code}
              </Typography>
              <Typography
                sx={{ width: "80%" }}
                fontSize="large"
                variant="body1"
                component="div"
              >
                {row?.description}
              </Typography>
            </Box>
          }
          secondary={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <List>
                {validationMessages.map((e, idx) => (
                  <ListItem key={e?.id || idx} disablePadding>
                    <ListItemIcon>
                      <ErrorOutlineIcon color="error" />
                    </ListItemIcon>
                    <ListItemText
                      primary={e?.message}
                      sx={{ color: "#d32f2f" }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

const DescriptionListItem = ({
  row,
  checked,
  handleToggle,
  labelId,
  current,
  fetchDocument,
  errorMessages,
  currentUser,
}) => {
  const [warningDialogOpen, setWarningDialogOpen] = React.useState(false);

  const [validationMessages, setValidationMessages] = React.useState([]);
  const id = `${current?.id}@${row?.id ?? row?.entityId}`;
  React.useEffect(() => {
    if (errorMessages?.length > 0) {
      setValidationMessages(errorMessages.filter((e) => e?.id === id));
    }
  }, [errorMessages, id]);

  const handleDelete = async () => {
    setWarningDialogOpen(false);
    await deleteUserDataSource(current?.document_id, current?.id, row?.id);
    await fetchDocument(current?.document_id);
    snackbar.success("Description Deleted Successfully.");
  };

  return (
    <>
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        containedBtn="close"
        onProceed={() => handleDelete()}
        title="Remove Description?"
        description="You are about to Remove a Description, this action is irreversible."
        closeLabel="Cancel"
        proceedLabel="Yes, Remove!"
      />
      <ListItem
        sx={{ p: 0 }}
        secondaryAction={
          row?.id && (
            <ListItemButton role={undefined}>
              <IconButton edge="start" aria-label="expo" sx={{ m: 0.5 }}>
                <FileUploadIcon color="success" sx={{ fontSize: "1.8rem" }} />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                sx={{ mx: 0.5 }}
                onClick={() => {
                  setWarningDialogOpen(true);
                }}
              >
                <DeleteIcon color="error" sx={{ fontSize: "1.8rem" }} />
              </IconButton>
            </ListItemButton>
          )
        }
      >
        <ListItemButton role={undefined} dense sx={{ p: 0 }}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(id) !== -1}
              tabIndex={-1}
              disableRipple
              onClick={handleToggle(id, {
                code: row?.entityId,
                kind: current?.kind,
                category: current?.category,
                suggest: row?.id ? false : true,
              })}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>

          <ListItemText
            id={labelId}
            primary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ width: "20%" }}
                  fontSize="large"
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                >
                  {row?.entityId}
                </Typography>
                <Typography
                  sx={{ width: "80%" }}
                  fontSize="large"
                  variant="body1"
                  component="div"
                  fontWeight="bold"
                >
                  {!row?.id && (
                    <Typography variant="body1">(Suggestion)</Typography>
                  )}
                  {row?.description}
                </Typography>
              </Box>
            }
            secondary={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <List>
                  {validationMessages.map((e, idx) => (
                    <ListItem key={e?.id || idx} disablePadding>
                      <ListItemIcon>
                        <ErrorOutlineIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={e?.message}
                        sx={{ color: "#d32f2f" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            }
          />
        </ListItemButton>
      </ListItem>
      <Box>
        <List sx={{ m: 0, ml: "5%" }} component="div">
          {row?.sources?.map((source, idx) => (
            <DescriptionSubListItem
              key={Math.floor(Math.random() * 100000 + (idx + 1))}
              checked={checked}
              handleToggle={handleToggle}
              row={source}
              index={idx}
              current={row}
              parent={current}
              errorMessages={errorMessages}
              labelId={`description-sub-list-item-${row.Id}-${idx}`}
            />
          ))}
        </List>
      </Box>
    </>
  );
};

const RowListItem = ({
  row,
  checked,
  handleToggle,
  labelId,
  setAddDescriptionOpen,
  setSelectedRow,
  documentId,
  fetchDocument,
  errorMessages,
  currentUser,
}) => {
  const [open, setOpen] = React.useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = React.useState(false);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    setActive(row?.principal);
  }, [row]);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleDelete = async () => {
    setWarningDialogOpen(false);
    await deleteUserEntity(documentId, row?.id);
    await fetchDocument(documentId);
    snackbar.toast(`${row?.kind} "${row?.text}" Deleted Successfully.`);
  };

  const handleActiveChange = async (event) => {
    setActive(event.target.checked);
    await setEntityPrincipal(documentId, row?.id);
    await fetchDocument(documentId);
    snackbar.toast(`${row?.kind} "${row?.text}" Updated Successfully.`);
  };

  return (
    <>
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        containedBtn="close"
        onProceed={() => handleDelete()}
        title={`Remove "${row?.text}" ${row?.kind}?`}
        description={`You are about to Remove a ${row?.kind}, this action is irreversible.`}
        closeLabel="Cancel"
        proceedLabel="Yes, Remove!"
      />
      <ListItem
        key={row?.Id}
        sx={{ py: 0 }}
        secondaryAction={
          <ListItemButton role={undefined}>
            {row.kind === "Diagnosis" &&
              currentUser?.user_type?.name === "U" && (
                <FormControlLabel
                  value={active}
                  control={
                    <Switch
                      checked={active}
                      color="primary"
                      onChange={handleActiveChange}
                    />
                  }
                  label="Principal"
                  labelPlacement="start"
                />
              )}
            <IconButton
              edge="start"
              aria-label="expo"
              sx={{ m: 0.5 }}
              // disabled={row?.sourceDescriptions?.length > 0}
              onClick={() => {
                setAddDescriptionOpen(true);
                setSelectedRow(row);
              }}
            >
              <AddIcon color="primary" sx={{ fontSize: "1.8rem" }} />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              sx={{ mx: 0.5 }}
              onClick={() => {
                setWarningDialogOpen(true);
              }}
            >
              <DeleteIcon color="error" sx={{ fontSize: "1.8rem" }} />
            </IconButton>
          </ListItemButton>
        }
      >
        <ListItemButton role={undefined} dense sx={{ py: 0 }}>
          <IconButton
            edge="end"
            aria-label={open ? "hide" : "show"}
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            {open ? (
              <ExpandLess sx={{ fontSize: "1.8rem" }} />
            ) : (
              <ExpandMore sx={{ fontSize: "1.8rem" }} />
            )}
          </IconButton>

          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(String(row?.id)) !== -1}
              tabIndex={-1}
              disableRipple
              onClick={handleToggle(String(row?.id), false)}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>

          <ListItemText
            id={labelId}
            primary={
              <>
                <Typography fontSize="large" variant="body1" component="div">
                  {`${row?.text} (${row?.dataSources?.length})`}{" "}
                </Typography>
                {row.negative && (
                  <small style={{ color: "red" }}>Negative</small>
                )}
              </>
            }
            secondary={
              currentUser?.user_type?.name === "U" && (
                <Typography
                  variant="body2"
                  component="div"
                  color={
                    row?.canPrincipal === true
                      ? "primary"
                      : row?.principal && row?.canPrincipal === false
                      ? "error"
                      : "success"
                  }
                >
                  {row?.canPrincipal === true
                    ? "Should be Principal"
                    : row?.principal && row?.canPrincipal === false
                    ? "Shouldn't be Principal"
                    : ""}
                </Typography>
              )
            }
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ ml: "15%", p: 0 }} component="div">
          {row?.dataSources?.map((st, idx) => (
            <DescriptionListItem
              key={Math.floor(Math.random() * 100000 + (idx + 1))}
              checked={checked}
              fetchDocument={fetchDocument}
              handleToggle={handleToggle}
              row={st}
              index={idx}
              current={row}
              errorMessages={errorMessages}
              labelId={`description-list-item-${row.Id}-${idx}`}
              currentUser={currentUser}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default function AzureEditableDocument({ documentId }) {
  const { currentUser } = useSelector((state) => state.auth);

  const [checked, setChecked] = React.useState([]);
  const [selectedRecords, setSelectedRecords] = React.useState([]);
  const [customizedText, setCustomizedText] = React.useState("");
  const [addRowOpen, setAddRowOpen] = React.useState(false);
  const [addDescriptionOpen, setAddDescriptionOpen] = React.useState(false);
  const [kind, setKind] = React.useState("Diagnosis");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [userDocument, setUserDocument] = React.useState({});

  const validateRecords = async (codes) => {
    try {
      setLoading(true);
      const errorMessages = await validateSelectedCodes(codes, documentId);
      setErrorMessages(errorMessages);
    } catch (err) {
      snackbar.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const fetchDocument = async (id) => {
    setLoading(true);
    const { document: doc, selections } = await getUserDocument(id);
    setUserDocument(doc);
    if (selections.length) {
      setChecked(selections.map((s) => s.id));
      validateRecords(selections, setErrorMessages);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (documentId) {
      fetchDocument(documentId);
    }
  }, [documentId]);

  const handleToggle = (value, object) => () => {
    value = String(value);
    const currentIndex = checked.indexOf(value);
    let newChecked = [...checked];
    let selectedRecordsArr = [...selectedRecords];
    if (value?.includes("@")) {
      let parent = "";
      const splittedValue = value.split("@"),
        numberOfParents = (value?.match(/@/g) || []).length;
      for (let i = 0; i < numberOfParents; i++) {
        parent += `${i > 0 ? "@" : ""}${splittedValue[i]}`;
        if (currentIndex === -1 && checked.indexOf(parent) === -1)
          newChecked.push(parent);
      }
    }
    if (currentIndex === -1) {
      newChecked.push(value);
      selectedRecordsArr.push({ ...object, id: value });
    } else {
      newChecked.splice(currentIndex, 1);
      newChecked = newChecked.filter((val) => !val.includes(`${value}@`));
      selectedRecordsArr = selectedRecordsArr.filter((r) => r?.id !== value);
    }
    setChecked(newChecked);
    setSelectedRecords(selectedRecordsArr);
    if (object) {
      validateRecords(selectedRecordsArr, setErrorMessages);
    }
  };

  React.useEffect(() => {
    if (userDocument?.entities?.length > 0) {
      let text = [];
      userDocument?.entities?.forEach((entity, idx, arr) => {
        text.push(
          <span key={Math.floor(Math.random() * 100000 + (idx + 1))}>
            {String(userDocument?.text).substring(
              idx === 0 ? 0 : arr[idx - 1]?.offset + arr[idx - 1]?.length,
              entity?.offset
            )}
          </span>
        );
        if (!!entity.kind)
          text.push(
            <PopoverCard
              row={entity}
              key={Math.floor(Math.random() * 100000 + (idx + 1))}
            />
          );
        else text.push(entity.text);
        if (
          idx === arr?.length - 1 &&
          entity?.offset + entity?.length < userDocument?.text?.length - 1
        ) {
          text.push(
            <span>
              {String(userDocument?.text).substring(
                entity?.offset + entity?.length,
                userDocument?.text?.length - 1
              )}
            </span>
          );
        }
      });
      setCustomizedText(text);
    } else {
      setCustomizedText(userDocument?.text);
    }
  }, [userDocument]);

  const handleExport = () => {
    snackbar.info("Preparing Data to be Exported.");
    const rows = [
      [
        "in Documentation",
        "Category",
        "Code",
        "Description",
        "Validation Notes",
      ],
    ];
    checked.forEach((value) => {
      if (!value.includes("@")) {
        const entity = userDocument?.entities?.find(
          (v) => v?.id === parseInt(value)
        );
        rows.push([entity.text, entity.kind, "", "", ""]);
        if (entity?.dataSources?.length > 0) {
          entity?.dataSources?.forEach((eds) => {
            checked.includes(`${entity?.id}@${eds?.id}`) &&
              rows.push(["", "", eds?.entityId, eds?.description, ""]);
            errorMessages
              .filter((e) => e?.id === `${entity?.id}@${eds?.id}`)
              ?.forEach((emsg) => rows.push(["", "", "", "", emsg?.message]));
            if (eds?.sources?.length > 0) {
              eds?.sources?.forEach((dsss) => {
                checked.includes(`${entity?.id}@${eds?.id}@${dsss?.code}`) &&
                  rows.push(["", "", dsss?.code, dsss?.description, ""]);
                errorMessages
                  .filter(
                    (e) => e?.id === `${entity?.id}@${eds?.id}@${dsss?.code}`
                  )
                  ?.forEach((emsg) =>
                    rows.push(["", "", "", "", emsg?.message])
                  );
              });
            }
          });
        }
      }
    });
    const csvContent =
      "data:text/csv;charset=utf-8," +
      rows.map((e) => e.map((s) => '"' + s + '"').join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
    snackbar.success("File Exported Successfully.");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 5,
        textAlign: "center",
        bgcolor: "background.paper",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "fixed",
            zIndex: "1000000000000",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            height: "100%",
            width: "100%",
            bgcolor: "#000000B3",
            overflow: "auto",
          }}
        >
          <CircularProgress size={100} />
          <Typography
            variant="bod1"
            component="div"
            color="#FFF"
            mt={5}
            fontWeight="bold"
          >
            Preparing Your Data Please Wait...
          </Typography>
        </Box>
      )}
      <AddRow
        setOpen={setAddRowOpen}
        open={addRowOpen}
        kind={kind}
        fetchDocument={fetchDocument}
        userDocument={userDocument}
      />
      <AddDescription
        setOpen={setAddDescriptionOpen}
        open={addDescriptionOpen}
        current={selectedRow}
        fetchDocument={fetchDocument}
      />
      <Typography
        component="div"
        variant="body1"
        sx={{
          border: "1px solid #ddd",
          px: 3,
          py: 1,
          mb: 1,
          // mx: 5,
          borderRadius: "5px",
          lineHeight: "2.5rem",
          width: "90%",
        }}
      >
        {customizedText}
      </Typography>
      <Box
        sx={{
          border: "1px solid #ddd",
          px: 3,
          py: 1,
          borderRadius: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircleIcon sx={{ fontSize: "medium", color: "green" }} />
        <Typography sx={{ ml: 1 }} component="div" variant="body1">
          Diagnosis
        </Typography>
        <CircleIcon sx={{ fontSize: "medium", color: "blue", ml: 3 }} />
        <Typography sx={{ ml: 1 }} component="div" variant="body1">
          Procedures
        </Typography>
        <CircleIcon sx={{ fontSize: "medium", color: "orange", ml: 3 }} />
        <Typography sx={{ ml: 1 }} component="div" variant="body1">
          Medication
        </Typography>
        <CircleIcon sx={{ fontSize: "medium", color: "red", ml: 3 }} />
        <Typography sx={{ ml: 1 }} component="div" variant="body1">
          Negative
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <List sx={{ width: "100%", bgcolor: "background.paper", p: 0, m: 0 }}>
          {[
            {
              Id: "main-diagnosis",
              Text: "Diagnosis",
              childs: userDocument?.entities?.filter(
                (aa) => aa?.kind === "Diagnosis"
              ),
              kind: "Diagnosis",
            },
            {
              Id: "main-procedures",
              Text: "Procedures",
              childs: userDocument?.entities?.filter(
                (aa) => aa?.kind === "Procedure"
              ),
              kind: "Procedure",
            },
            {
              Id: "main-medication",
              Text: "Medication",
              childs: userDocument?.entities?.filter(
                (aa) => aa?.kind === "Medication"
              ),
              kind: "Medication",
            },
          ]?.map((value, idx) => {
            const labelId = `checkbox-list-label-${value?.Id}`;
            return (
              <MainListItem
                currentUser={currentUser}
                key={`main-${idx}`}
                setKind={setKind}
                setAddRowOpen={setAddRowOpen}
                setAddDescriptionOpen={setAddDescriptionOpen}
                setSelectedRow={setSelectedRow}
                row={value}
                checked={checked}
                handleToggle={handleToggle}
                labelId={labelId}
                documentId={userDocument?.id}
                fetchDocument={fetchDocument}
                errorMessages={errorMessages}
              />
            );
          })}
        </List>
        {/* {errorMessages.length > 0 && (
          <Box
            sx={{
              width: "30%",
              minHeight: "100px",
              maxHeight: "500px",
              overflowY: "auto",
              position: "sticky",
              top: 0,
              p: 0,
              m: 0,
            }}
          >
            <List sx={{ bgcolor: "background.paper" }}>
              {errorMessages.map((e) => (
                <ListItemText key={e} sx={{ color: "red" }}>
                  Id is : {e?.id}
                  {"- "}
                  {e?.message}{" "}
                </ListItemText>
              ))}
            </List>
          </Box>
        )} */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "20%",
          bgcolor: "#fff",
          position: "fixed",
          bottom: 0,
          maxHeight: 100,
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
        }}
      >
        <Button
          disabled={checked.length === 0}
          variant="contained"
          onClick={handleExport}
          color="success"
          sx={{ color: "#fff", mx: 2, my: 1 }}
        >
          Export Data
        </Button>
      </Box>
    </Box>
  );
}
