import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function Uploading({ progress }) {
	return (
		<Box
			sx={{
				position: "fixed",
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				backgroundColor: "rgba(0,0,0,0.5)",
				zIndex: 3000,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				marginTop: "-10%",
			}}
		>
			<Box sx={{ position: "relative", display: "inline-flex" }}>
				<CircularProgress variant="determinate" value={progress} size={200} sx={{ color: "#fff" }} />
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="h2" fontWeight="bold" component="div" color="#fff">
						{`${Math.round(progress)}%`}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

export default Uploading;
