import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkAuth } from "redux/features/auth.slice";
import MainLoader from "components/MainLoader/MainLoader";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentUser, loading, token } = useSelector((state) => state.auth);

  useEffect(() => {
    !(location?.pathname === "/login") && dispatch(checkAuth());
  }, []);

  if (!token && !loading) return <Navigate to="/login" />;
  if (loading) return <MainLoader />;
  if (!!currentUser) return children;

  return <Navigate to="/" />;
};

export default PrivateRoute;
