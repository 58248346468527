import { LoadingButton } from "@mui/lab";
import { Box, Chip, CircularProgress, Collapse, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { snackbarErrorMsg } from "config/constants";
import { isEmpty, toLower } from "lodash";
import { searchByCodeAndDescription } from "models/Code";
import { submitUserDataSource } from "models/DataSource";
import { submitUserEntity } from "models/Entity";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import snackbar from "utils/snackbar";
import DoneIcon from "@mui/icons-material/Done";

function ReadAndCodeDialog({
  open,
  setOpen,
  fetchDocument,
  userDocument,
  text,
  beginOffset,
}) {
  const { currentUser } = useSelector((state) => state.auth);
  const [current, setCurrent] = useState({});
  const [codes, setCodes] = useState([]);
  const [kind, setKind] = useState("");
  const [category, setCategory] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [negative, setNegative] = useState(false);
  const [option, setOption] = useState(0);

  const [loading, setLoading] = useState(false);

  const handleOptionChange = (selectionKind) => {
    if (searchKey.length > 0) {
      setLoading(true);
      const type = currentUser?.user_type[toLower(selectionKind)];
      searchByCodeAndDescription(type, searchKey)
        .then((data) => {
          setCodes(data);
        })
        .catch((error) => {
          snackbar.error(error?.message || "Can't Fetch Search List.");
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = async (code) => {
    try {
      setLoading(true);
      // let extraTraits = negative ? { negativity: "negative" } : {};
      let data = {
        text: text,
        offset: beginOffset,
        length: text.length,
        category: category,
        confidenceScore: 1,
        // ...extraTraits,
      };
      const entity = await submitUserEntity(userDocument.id, data);
      setCurrent(entity);
      data = {
        name: currentUser?.user_type[toLower(kind)],
        entityId: code?.code,
      };
      await submitUserDataSource(entity?.document_id, entity?.id, data);
      setOpen(false);
      await fetchDocument(entity?.document_id);

      snackbar.success(`${kind} Created Successfully.`);
    } catch (error) {
      snackbar.error(snackbarErrorMsg);
      console.log(error);
    } finally {
      resetForm();
      setOpen(false);
    }
  };

  const handleSearchKeyChange = (e) => {
    setSearchKey(e?.target?.value);
    setOption(0);
    setCodes([]);
  };

  useEffect(() => {
    setSearchKey(text);
  }, [text]);

  const resetForm = () => {
    setCodes([]);
    setOption(0);
    setLoading(false);
    setSearchKey("");
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        setOpen(false);
        resetForm();
      }}
      aria-labelledby="add-user-first-name"
      aria-describedby="add-user-description"
    >
      {/* <DialogTitle id="add-user-first-name">Read And Code</DialogTitle> */}
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TextField
              value={searchKey}
              inputProps={{ style: { textAlign: "center" } }}
              placeholder="Selection"
              label="Selection"
              focused
              onChange={handleSearchKeyChange}
              sx={{ width: "50%", my: 3 }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                p: 3,
              }}
            >
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                sx={{
                  mx: 3,
                  py: 2,
                  px: 4,
                  minWidth: 300,
                  ...(option === 1 ? { color: "white" } : {}),
                }}
                variant={option === 1 ? "contained" : "outlined"}
                onClick={() => {
                  setOption(1);
                  setKind("Diagnosis");
                  setCategory("Diagnosis");
                  setNegative(false);
                  handleOptionChange("Diagnosis");
                }}
                color="success"
              >
                Diagnosis
              </LoadingButton>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                sx={{ mx: 3, py: 2, px: 4, minWidth: 300 }}
                variant={option === 3 ? "contained" : "outlined"}
                onClick={() => {
                  setOption(3);
                  setKind("Procedure");
                  setCategory("ExaminationName");
                  setNegative(false);
                  handleOptionChange("Procedure");
                }}
              >
                Procedure
              </LoadingButton>
            </Box>
          </Box>
          <Collapse
            in={codes?.length > 0}
            timeout={800}
            mountOnEnter
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                border: "1px solid #9e9e9e",
                borderRadius: "5px",
              }}
            >
              {codes?.map((c) => (
                <Chip
                  sx={{
                    m: 2,
                    p: 3,
                    maxWidth: "40%",
                    "& .MuiChip-label": {
                      overflowWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    },
                    height: "auto",
                  }}
                  label={`(${c?.code})  -  ${c?.description}`}
                  onClick={() => handleSubmit(c)}
                  deleteIcon={<DoneIcon />}
                  disabled={loading}
                  onDelete={() => handleSubmit(c)}
                  variant="outlined"
                />
              ))}
            </Box>
          </Collapse>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            resetForm();
          }}
          color="error"
          sx={{ p: 2 }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReadAndCodeDialog;
