import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Layout from "components/Layout/Layout";
import { validateXML } from "models/Validate";
import { useState } from "react";
import { ValidationErrorsList } from "./ValidationErrorsList";

function UploadXML() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const validationErrors = await validateXML(formData, setLoading);
      setErrorMessages(validationErrors);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          bgcolor: "background.paper",
          display: "flex",
          p: 3,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={handleFormSubmit}
          style={{
            display: "flex",
            width: "80%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <input
            accept=".xml"
            type="file"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <label htmlFor="file-input">
            <Button variant="outlined" component="span" color="primary">
              Select XML File
            </Button>
          </label>
          {selectedFile && <p>Selected File: {selectedFile.name}</p>}
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={!selectedFile}
            loading={loading}
            loadingPosition="center"
          >
            Upload
          </LoadingButton>
        </form>
        {errorMessages?.length > 0 && ValidationErrorsList(errorMessages)}
      </Box>
    </Layout>
  );
}

export default UploadXML;
