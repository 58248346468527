import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ReadAndCodeDocument from "components/ReadAndCodeDocument/ReadAndCodeDocument";
import Layout from "components/Layout/Layout";
import {
  getUserManualDocuments,
  submitUserManualDocument,
} from "models/Document";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import snackbar from "utils/snackbar";

function SubmitReadAndCode() {
  const [jobName, setJobName] = useState("");
  const [text, setText] = useState("");
  const [row, setRow] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [remainings, setRemainings] = React.useState(0);
  const [loadingUserInfo, setLoadingUserInfo] = React.useState(false);

  const { currentUser } = useSelector((state) => state.auth);

  const handleJobNameChange = (e) => {
    setJobName(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const loadContent = async () => {
    setLoadingUserInfo(true);
    try {
      const documents = await getUserManualDocuments();
      setJobName(`RC-${currentUser.name}-${documents.length + 1}`);
    } catch (error) {
      snackbar.error("Failed to fetch user documents.");
    } finally {
      setLoadingUserInfo(false);
    }
  };

  useEffect(() => {
    setRemainings(parseInt(currentUser.submissions));
    loadContent();
  }, []);

  const handleSubmit = async () => {
    try {
      const payload = { text, name: jobName };
      setLoading(true);
      snackbar.toast("Analyzing Document ... Please be Patient.");
      const documentId = await submitUserManualDocument(payload);
      setRow(documentId);
      snackbar.success(
        "Document Analyzed Successfully, Please Check your Result."
      );
    } catch (error) {
      snackbar.error(
        error?.message ||
          "Error Occured While Submitting your Request, Please Try Again."
      );
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setText("");
    setLoading(false);
  };

  const isDisabled = !text || text.length > 10000 || remainings <= 0;

  return (
    <Layout>
      <Box
        sx={{
          // flexGrow: 1,
          // width: "100%",
          bgcolor: "background.paper",
          display: "flex",
          p: 3,
          // width: "100vw",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" component="div">
          Your Remaining Submissions: {remainings}
        </Typography>
        {loadingUserInfo ? (
          <Skeleton sx={{ width: "100%" }} />
        ) : (
          <TextField
            id="outlined-textfield"
            value={jobName}
            label="Job Name"
            placeholder="Job Name"
            fullWidth
            sx={{ m: 2 }}
            onChange={handleJobNameChange}
          />
        )}
        <TextField
          sx={{ m: 2 }}
          value={text}
          id="outlined-textarea"
          label="Please Add Clinical Documentation to be Coded."
          placeholder="Clinical Documentation to be Coded."
          multiline
          rows={4}
          fullWidth
          onChange={handleTextChange}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingButton
            sx={{ mt: 5, mx: 2, width: "30%" }}
            variant="contained"
            disabled={isDisabled}
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="center"
            startIcon={<SaveIcon />}
            size="large"
          >
            Submit
          </LoadingButton>
          <Button
            sx={{ mt: 5, mx: 2, width: "30%" }}
            variant="outlined"
            onClick={resetForm}
            color="error"
            disabled={loading}
            startIcon={<ClearIcon />}
            size="large"
          >
            Clear
          </Button>
        </Box>
      </Box>
      {!!row && (
        <Box
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            mt: 5,
            bgcolor: "background.paper",
            display: "flex",
            p: 3,
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Collapse in={!!row} timeout={400} mountOnEnter unmountOnExit>
            <ReadAndCodeDocument documentId={row} />
          </Collapse>
        </Box>
      )}
    </Layout>
  );
}

export default SubmitReadAndCode;
