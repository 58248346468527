import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Layout from "components/Layout/Layout";
import { getSubmissionsResult } from "models/Validate";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ValidationErrorsList } from "./ValidationErrorsList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const SubmissionsResult = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    setData(await getSubmissionsResult(id));
    setLoading(false);
  };

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      <IconButton
        onClick={goBack}
        color="primary"
        sx={{ width: "fit-content", background: "white", borderRadius: 0 }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {data && (
        <Box
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            p: 3,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data?.length > 0 ? (
            ValidationErrorsList(data)
          ) : (
            <Alert severity="error">No Result Found!</Alert>
          )}
        </Box>
      )}
    </Layout>
  );
};
