import AuthPage from "pages/AuthPage/AuthPage";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";
import paths from "./paths";
import { SubmissionsResult } from "pages/UploadXML/SubmissionsResult";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route exact key="/login" path="/login" element={<AuthPage />} />
        {paths.map(
          ({ path, isPrivate, exact, component, isSubHeader }) =>
            !isSubHeader &&
            (isPrivate ? (
              <Route
                exact={exact}
                key={path}
                path={path}
                element={<PrivateRoute children={component} />}
              />
            ) : (
              <Route exact={exact} key={path} path={path} element={component} />
            ))
        )}
        <Route path="/submissions/:id" element={<SubmissionsResult />} />
        <Route
          exact={true}
          key="/submissions/:id"
          path="/submissions/:id"
          element={<PrivateRoute children={<SubmissionsResult />} />}
        />
        <Route exact={true} key="*" path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
