import { makeStyles } from "@mui/styles";

export default makeStyles(({ spacing }) => ({
	root: {
		padding: spacing(4, 5),
	},
	form: {
		maxWidth: spacing(40),
		margin: "0 auto",
	},
	formActions: {
		marginTop: spacing(5),
		display: "flex",
		justifyContent: "center",
	},
}));
