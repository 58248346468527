import React, { memo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Grow from "@mui/material/Grow";
import useStyles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Grow {...props} ref={ref} />;
});

function WarningDialog({ open, onClose, onProceed, closeLabel, proceedLabel, title, description, containedBtn }) {
	const classes = useStyles();

	return (
		<Dialog
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
			TransitionProps={{
				mountOnEnter: true,
				unmountOnExit: true,
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className={classes.dialog}
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{description}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" variant={containedBtn === "close" ? "contained" : "text"}>
					{closeLabel}
				</Button>
				<Button onClick={onProceed} variant={containedBtn === "proceed" ? "contained" : "text"} color="primary">
					{proceedLabel}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default memo(WarningDialog);
