import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import snackbar from "utils/snackbar";
import { snackbarErrorMsg } from "config/constants";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { forgotPassword, resetPassword, verifyOtp } from "models/Auth";
import PasswordVisibility from "components/PasswordVisibility/PasswordVisibility";

function ForgotPassword({ open, setOpen }) {
	const [step, setStep] = useState(1);
	const [email, setEmail] = useState("");
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [invalidEmailMessage, setInvalidEmailMessage] = useState("");

	const [otp, setOtp] = useState("");
	const [invalidOtp, setInvalidOtp] = useState(false);
	const [invalidOtpMessage, setInvalidOtpMessage] = useState("");
	const [timer, setTimer] = useState("02:00");
	const [timeUp, setTimeUp] = useState(false);

	const [password, setPassword] = useState("");
	const [rePassword, setRePassword] = useState("");
	const [showPassword, toggleShowPassword] = useState(false);
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [invalidRePassword, setInvalidRePassword] = useState(false);
	const [invalidPasswordMessage, setInvalidPasswordMessage] = useState("");
	const [invalidRePasswordMessage, setInvalidRePasswordMessage] = useState("");

	const Ref = useRef(null);

	useEffect(() => {
		if (step === 2 && timeUp) {
			snackbar.toast("Forget Password session expired, Please Try Again.");
			resetForm();
		}
	}, [timeUp, step]);

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleOtpChange = (e) => {
		const val = e.target.value;
		if (val.length <= 6) setOtp(val);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleRePasswordChange = (e) => {
		setRePassword(e.target.value);
	};

	const handleEmailBlur = (e) => {
		const val = e.target.value;
		const regex =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let check = !regex.test(val);
		let message = "";
		if (check) {
			message = "Please Enter Valid Email Address.";
		}
		setInvalidEmail(check);
		setInvalidEmailMessage(message);
	};

	const handleOtpBlur = (e) => {
		const val = e.target.value;
		const regex = /^[0-9]{6}$/;
		let check = !regex.test(val);
		let message = "";
		if (check) {
			message = "Please Enter a Valid OTP of 6-Digits.";
		}
		setInvalidOtp(check);
		setInvalidOtpMessage(message);
	};

	const validatePassword = () => {
		const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
		const check = !strongRegex.test(password);
		setInvalidPassword(check);
		if (check) {
			setInvalidPasswordMessage(
				"The string must be eight characters or longer. at least 1 lowercase, 1 uppercase, 1 numeric and 1 special character."
			);
		} else {
			setInvalidPasswordMessage("");
		}
	};

	const validateRePassword = () => {
		const check = rePassword !== password;
		setInvalidRePassword(check);
		if (check) {
			setInvalidRePasswordMessage("Re-Type Password and Password is not Identical.");
		} else {
			setInvalidRePasswordMessage("");
		}
	};

	const handleSubmit = async () => {
		try {
			let data = {};

			switch (step) {
				case 1:
					data = {
						email,
					};
					await forgotPassword(data);
					snackbar.toast("Otp Sent, Please Check your Inbox.");
					setStep(2);
					setTimeout(() => onClickReset(), 500);
					break;
				case 2:
					data = {
						email,
						otp,
					};
					const { token } = await verifyOtp(data);
					localStorage.setItem("token", token);
					snackbar.toast("OTP Verified, Please Enter Your New Password.");
					setStep(3);
					break;
				case 3:
					data = {
						password,
						confirmPassword: rePassword,
					};
					await resetPassword(data);
					snackbar.toast("Password Changed Successfully.");
					resetForm();
					break;
				default:
					resetForm();
					break;
			}
		} catch (error) {
			snackbar.error(error?.response?.data?.message || snackbarErrorMsg);
			console.log({ error });
		}
	};

	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		return {
			total,
			minutes,
			seconds,
		};
	};

	const startTimer = (e) => {
		let { total, minutes, seconds } = getTimeRemaining(e);
		if (total >= 0) {
			setTimer((minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
		} else {
			setTimeUp(true);
			clearInterval(Ref.current);
		}
	};

	const clearTimer = (e) => {
		setTimer("02:00");
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000);
		Ref.current = id;
	};

	const getDeadTime = () => {
		let deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + 120);
		return deadline;
	};

	const onClickReset = () => {
		clearTimer(getDeadTime());
	};

	const resetForm = () => {
		setOpen(false);
		setTimeUp(false);
		setStep(1);
		setEmail("");
		setInvalidEmail(false);
		setInvalidEmailMessage("");
		setOtp("");
		setInvalidOtp(false);
		setInvalidOtpMessage("");
		setTimer("02:00");
		setPassword("");
		setRePassword("");
		setInvalidPassword(false);
		setInvalidRePassword(false);
		setInvalidPasswordMessage("");
		setInvalidRePasswordMessage("");
	};

	const isDisabled =
		(step === 1 && (!email || invalidEmail)) ||
		(step === 2 && (timeUp || !otp || invalidOtp)) ||
		(step === 3 && (!password || !rePassword || invalidPassword || invalidRePassword));

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={open}
			aria-labelledby="forget-password-name"
			aria-describedby="forget-password"
		>
			<DialogTitle id="forget-password-name">Forget Password</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Collapse sx={{ width: "60%" }} in={step === 1} timeout={100} mountOnEnter unmountOnExit>
						<TextField
							sx={{ m: 2 }}
							value={email}
							autoComplete="remove-it"
							onChange={handleEmailChange}
							onBlur={handleEmailBlur}
							id="email"
							label="Email"
							type="email"
							fullWidth
							helperText={
								invalidEmail ? invalidEmailMessage : "Please Enter Your Email Address to Send you OTP."
							}
							variant="outlined"
							error={invalidEmail}
						/>
					</Collapse>
					<Collapse sx={{ width: "60%" }} in={step === 2} timeout={400} mountOnEnter unmountOnExit>
						<TextField
							sx={{ m: 2 }}
							inputProps={{ style: { textAlign: "center" } }}
							value={otp}
							autoComplete="remove-it"
							onChange={handleOtpChange}
							onBlur={handleOtpBlur}
							id="otp"
							label="OTP"
							type="tel"
							fullWidth
							helperText={
								invalidOtp ? invalidOtpMessage : "Please enter OTP of 6-digits before the counter timeout."
							}
							variant="outlined"
							error={invalidOtp}
						/>
						<Typography
							variant="body1"
							component="div"
							sx={{
								textAlign: "center",
								fontWeight: "bold",
								color: "#00bcf2",
							}}
							onClick={onClickReset}
						>
							{timer}
						</Typography>
					</Collapse>
					<Collapse sx={{ width: "60%" }} in={step === 3} timeout={100} mountOnEnter unmountOnExit>
						<TextField
							margin="normal"
							id="create-user-password"
							value={password}
							autoComplete="remove-it"
							onChange={handlePasswordChange}
							onBlur={() => {
								validatePassword();
								validateRePassword();
							}}
							label="Password"
							type={showPassword ? "text" : "password"}
							firstName="password"
							fullWidth
							error={invalidPassword}
							helperText={invalidPasswordMessage}
							{...{
								InputProps: {
									endAdornment: (
										<PasswordVisibility
											showPassword={showPassword}
											onClick={() => toggleShowPassword((st) => !st)}
										/>
									),
								},
							}}
						/>
						<TextField
							margin="normal"
							id="create-user-re-password"
							value={rePassword}
							autoComplete="remove-it"
							onChange={handleRePasswordChange}
							label="Re-Type Password"
							type={showPassword ? "text" : "password"}
							firstName="rePassword"
							fullWidth
							onBlur={() => {
								validatePassword();
								validateRePassword();
							}}
							error={invalidRePassword}
							helperText={invalidRePasswordMessage}
							{...{
								InputProps: {
									endAdornment: (
										<PasswordVisibility
											showPassword={showPassword}
											onClick={() => toggleShowPassword((st) => !st)}
										/>
									),
								},
							}}
						/>
					</Collapse>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={resetForm}>Cancel</Button>
				<Button onClick={handleSubmit} disabled={isDisabled} variant="contained">
					Send
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ForgotPassword;
