import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import snackbar from "utils/snackbar";
import { snackbarErrorMsg } from "config/constants";
import * as _ from "lodash";
import { isEmpty } from "lodash";
import { submitUserEntity } from "models/Entity";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function AddRow({ open, setOpen, kind, userDocument, fetchDocument }) {
  const theme = useTheme();

  const [text, setText] = useState("");
  const [beginOffset, setBeginOffset] = useState(0);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [traits, setTraits] = useState([]);
  const [negative, setNegative] = useState(false);

  useEffect(() => {
    switch (kind) {
      case "Diagnosis":
        setCategories(["SymptomOrSign", "Diagnosis"]);
        break;
      case "Procedure":
        setCategories(["ExaminationName", "TreatmentName"]);
        break;
      case "Medication":
        setCategories(["MedicationClass", "MedicationName", "MedicationForm"]);
        break;
      default:
        break;
    }
  }, [kind, open]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleNegativeChange = (e) => {
    setNegative(e.target.checked);
  };

  const handleSubmit = async () => {
    try {
      setOpen(false);
      let extraTraits = negative ? { negativity: "negative" } : {};
      let data = {
        text: text,
        offset: beginOffset,
        length: text.length,
        category: category,
        confidenceScore: 1,
        ...extraTraits,
      };
      await submitUserEntity(userDocument.id, data);
      await fetchDocument(userDocument.id);
      snackbar.toast(`${kind} Created Successfully.`);
    } catch (error) {
      snackbar.error(error.message || snackbarErrorMsg);
      console.log(error);
    } finally {
      resetForm();
    }
  };

  const handleSelection = () => {
    const selection = window.getSelection();
    // selection.getRangeAt(0).setStart(document.getElementById("uinq-par"), 0);
    // selection.getRangeAt(0).setEnd(document.getElementById("uinq-par"), mainEntities?.text?.length);
    const range = selection.getRangeAt(0).cloneRange();
    let start = range.startOffset,
      end = range.endOffset,
      textSelected = selection.toString();
    if (textSelected.length === 0) {
      start = 0;
      end = 0;
      textSelected = "";
    } else {
      let invalidEntity = userDocument?.entities?.find(
        (e) =>
          (start <= e.offset && end >= e.offset) ||
          (start <= e.offset + e.length && start >= e.offset)
      );
      if (!isEmpty(invalidEntity)) {
        if (window.getSelection) {
          if (window.getSelection().empty) {
            // Chrome
            window.getSelection().empty();
          } else if (window.getSelection().removeAllRanges) {
            // Firefox
            window.getSelection().removeAllRanges();
          }
        } else if (document.selection) {
          // IE?
          document.selection.empty();
        }
        start = 0;
        end = 0;
        textSelected = "";
        snackbar.error(
          `Overlapping with Analyzed Entity "${invalidEntity.text}" as ${invalidEntity.kind}`
        );
      }
    }

    setText(textSelected);
    setBeginOffset(start);
  };

  const resetForm = () => {
    setText("");
    setBeginOffset(0);
    setCategory("");
    setTraits([]);
    setNegative(false);
  };

  const isDisabled = !text || !category;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => {
        setOpen(false);
        resetForm();
      }}
      aria-labelledby="add-user-first-name"
      aria-describedby="add-user-description"
    >
      <DialogTitle id="add-user-first-name">Add New {kind} Entity</DialogTitle>
      <DialogContent>
        <Typography
          component="div"
          variant="body1"
          sx={{
            border: "1px solid #ddd",
            p: 3,
            mb: 1,
            // mx: 5,
            textAlign: "center",
            borderRadius: "5px",
            lineHeight: "2.5rem",
            width: "90%",
          }}
          onMouseUp={handleSelection}
        >
          <p id="uinq-par">{userDocument?.text}</p>
        </Typography>
        <TextField
          sx={{ my: 2 }}
          autoFocus
          value={text}
          autoComplete="remove-it"
          onChange={handleTextChange}
          id="text"
          label="Text"
          type="text"
          fullWidth
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            fullWidth
            labelId="category-label"
            id="category"
            value={category}
            onChange={handleCategoryChange}
            input={<OutlinedInput label="Category" />}
            MenuProps={MenuProps}
          >
            {categories.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, traits, theme)}
              >
                {_.startCase(name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            mt: 2,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                onChange={handleNegativeChange}
                value={negative}
                color="primary"
              />
            }
            label="Negative"
            labelPlacement="start"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            resetForm();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isDisabled}
          variant="contained"
        >
          Add {kind}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddRow;
