import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  if (!!token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
