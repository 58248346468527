import {
  startAuth,
  logoutUser,
  checkAuth,
  authUserSuccess,
  authFail,
} from "redux/features/auth.slice";
import { put, call, takeLatest } from "redux-saga/effects";
import snackbar from "utils/snackbar";
import axios from "utils/api";

const signInWithEmailAndPassword = (email, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/auth/login", {
        username: email,
        password,
      })
      .then((data) => {
        const {
          data: {
            data: { token },
          },
        } = data;
        resolve(token);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const onAuthStateChanged = (authToken) => {
  return new Promise((resolve, reject) => {
    let config = {};

    if (authToken) {
      config = { headers: { Authorization: `Bearer ${authToken}` } };
    }

    axios
      .get("/auth/user", config)
      .then((data) => {
        const {
          data: {
            data: { user, token },
          },
        } = data;
        resolve({ user, token });
      })
      .catch((err) => {
        console.log({ err });
        reject({ code: "auth/user-not-found" });
      });
  });
};

const signOut = () => {
  return new Promise((resolve, reject) => {
    axios
      .post("/auth/logout")
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

function* handleAuthUser(action) {
  try {
    // User is logging into his account
    const { email, password } = yield action.payload.fields;
    const token = yield call(
      signInWithEmailAndPassword,
      String(email).toLowerCase(),
      password
    );
    yield put(checkAuth(token));
  } catch (error) {
    yield put(authFail({ email: "Invalid Email or Password." }));
  }
}

function* handleLogoutUser() {
  yield call(signOut);
  yield localStorage.removeItem("isAuth");
  yield localStorage.removeItem("token");
}

// eslint-disable-next-line consistent-return
function* handleCheckAuth(action) {
  try {
    const { user, token } = yield call(onAuthStateChanged, action.payload);
    if (!user)
      return yield put(authFail({ email: "Invalid Email or Password." }));
    yield put(authUserSuccess({ user, token }));
    yield localStorage.setItem("isAuth", true);
    yield localStorage.setItem("token", token);
  } catch (error) {
    console.log({ error });
    yield put(authFail({ email: "Invalid Email or Password." }));
  }
}

export default function* watchAuth() {
  yield takeLatest(startAuth.toString(), handleAuthUser);
  yield takeLatest(logoutUser.toString(), handleLogoutUser);
  yield takeLatest(checkAuth.toString(), handleCheckAuth);
}
