import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	palette: {
		primary: { main: "#0B81C1" },
		secondary: { main: "#1F1F2C" },
		tertiary: {
			main: "#000",
		},
		success: {
			main: "#4caf50",
		},
		error: {
			main: "#d32f2f",
		},
		warning: {
			main: "#ff9800",
		},
		background: {
			default: "rgb(232, 232, 239)",
		},
		text: {
			primary: "#000",
			secondary: "#323333",
			tertiary: "#2D2C2C",
			quat: "#81807F",
		},
	},
	shape: {
		borderRadius: 5,
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					lineHeight: 1.6,
				},
			},
		},
	},
});

export default responsiveFontSizes(theme);
