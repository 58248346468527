import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Layout from "components/Layout/Layout";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import Collapse from "@mui/material/Collapse";
import snackbar from "utils/snackbar";
import AzureEditableDocument from "components/AzureEditableDocument/AzureEditableDocument";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import {
  acceptConsent,
  getUserDocument,
  getUserDocuments,
  submitUserDocuments,
} from "models/Document";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
} from "@mui/material";

function AnalyzeDocument() {
  const [jobName, setJobName] = useState("");
  const [text, setText] = useState("");
  const [row, setRow] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [remainings, setRemainings] = React.useState(0);
  const [loadingUserInfo, setLoadingUserInfo] = React.useState(false);
  const [open, setOpen] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    acceptConsent();
    setOpen(false);
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  const handleJobNameChange = (e) => {
    setJobName(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const loadContent = async () => {
    setLoadingUserInfo(true);
    try {
      const documents = await getUserDocuments();
      setJobName(`${currentUser.name}-${documents.length + 1}`);
    } catch (error) {
      snackbar.error("Failed to fetch user documents.");
    } finally {
      setLoadingUserInfo(false);
    }
  };

  useEffect(() => {
    if (!currentUser.consent) handleOpen();
    setRemainings(parseInt(currentUser.submissions));
    loadContent();
  }, []);

  const handleSubmit = async () => {
    try {
      const payload = { text, name: jobName };
      setLoading(true);
      snackbar.toast("Analyzing Document ... Please be Patient.");
      const documentId = await submitUserDocuments(payload);
      setRow(documentId);
      snackbar.success(
        "Document Analyzed Successfully, Please Check your Result."
      );
    } catch (error) {
      snackbar.error(
        error?.message ||
          "Error Occured While Submitting your Request, Please Try Again."
      );
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setText("");
    setLoading(false);
  };

  const isDisabled = !text || text.length > 10000 || remainings <= 0;

  return (
    <Layout>
      <Dialog open={open} onClose={handleDialogClose} maxWidth="md">
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          <ol>
            <li>
              <b>Data Privacy:</b> The system does not require or request any
              patient-identifiable data or sensitive information to operate. We
              are committed to safeguarding patient data privacy and security.
            </li>
            <li>
              <b>Medical Neutrality:</b> The system is designed to be neutral
              and does not interfere with any medical conditions, diagnosed
              patients, or decisions that impact patient conditions. It operates
              solely with unidentified data for coding purposes.
            </li>
            <li>
              <b>Human Oversight:</b> The system requires human intervention
              from individuals responsible for making the final decisions based
              on the generated results. It is a tool to assist, not replace,
              human expertise.
            </li>
            <li>
              <b>User Eligibility:</b> By using the system, you confirm that you
              are eligible and authorized to use the codes and services
              provided. Your use of the system signifies your agreement with the
              terms outlined above.
            </li>
            <li>
              <b>Liability:</b> While the system is designed to provide accurate
              coding results, it is important to acknowledge that no automated
              system is infallible. The organization disclaims liability for any
              errors or consequences arising from the use of the system.
            </li>
            <li>
              <b>Data Usage:</b> The system does not collect or process any
              patient-identifiable data or sensitive information to operate,
              ensuring that patient information remains secure and private.
            </li>
            <li>
              <b>Compliance:</b> Our system is designed to comply with
              healthcare regulations, and follows best practices to ensure data
              security and compliance as we can.
            </li>
            <li>
              <b>User Responsibilities:</b> Users are responsible for ensuring
              the accuracy of the data provided and making informed decisions
              based on the system's output. The system serves as a tool, and
              ultimate decision-making lies with the user.
            </li>
            <li>
              <b>Contact Information:</b> For any questions, concerns, or to
              report issues related to the system, please reach out to your
              vendor.{" "}
            </li>
            <li>
              <b>Accessibility:</b> This disclaimer is easily accessible to all
              users, ensuring transparency and accountability.
            </li>
            <li>
              <b>Regulatory Review:</b> This disclaimer has been reviewed to
              align with the specific requirements for AI clinical coding
              systems in our jurisdiction.
            </li>
          </ol>
          <p>
            <b>
              Clicking "continue" implies your acceptance of all the terms and
              conditions stated in this comprehensive disclaimer.
            </b>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          // flexGrow: 1,
          // width: "100%",
          bgcolor: "background.paper",
          display: "flex",
          p: 3,
          // width: "100vw",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" component="div">
          Your Remaining Submissions: {remainings}
        </Typography>
        {loadingUserInfo ? (
          <Skeleton sx={{ width: "100%" }} />
        ) : (
          <TextField
            id="outlined-textfield"
            value={jobName}
            label="Job Name"
            placeholder="Job Name"
            fullWidth
            sx={{ m: 2 }}
            onChange={handleJobNameChange}
          />
        )}
        <TextField
          sx={{ m: 2 }}
          value={text}
          id="outlined-textarea"
          label="Please Add Clinical Documentation to be Coded."
          placeholder="Clinical Documentation to be Coded."
          multiline
          rows={4}
          fullWidth
          onChange={handleTextChange}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingButton
            sx={{ mt: 5, mx: 2, width: "30%" }}
            variant="contained"
            disabled={isDisabled}
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="center"
            startIcon={<SaveIcon />}
            size="large"
          >
            Submit
          </LoadingButton>
          <Button
            sx={{ mt: 5, mx: 2, width: "30%" }}
            variant="outlined"
            onClick={resetForm}
            color="error"
            disabled={loading}
            startIcon={<ClearIcon />}
            size="large"
          >
            Clear
          </Button>
        </Box>
      </Box>
      {!!row && (
        <Box
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            mt: 5,
            bgcolor: "background.paper",
            display: "flex",
            p: 3,
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Collapse in={!!row} timeout={400} mountOnEnter unmountOnExit>
            <AzureEditableDocument documentId={row} />
          </Collapse>
        </Box>
      )}
    </Layout>
  );
}

export default AnalyzeDocument;
