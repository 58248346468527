import React from "react";
import AnalyzeDocument from "pages/AnalyzeDocument/AnalyzeDocument";
import AzureDocuments from "pages/AzureDocuments/AzureDocuments";
import SubmitReadAndCode from "pages/SubmitReadAndCode/SubmitReadAndCode";
import ReadAndCode from "pages/ReadAndCode/ReadAndCode";
import UploadXML from "pages/UploadXML/UploadXML";
import { PreviousUploads } from "pages/UploadXML/PreviousUploads";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    isSubHeader: true,
    title: "One Click Coded",
  },
  {
    path: "/",
    isPrivate: true,
    exact: true,
    component: <AnalyzeDocument />,
    tab: {
      label: "Start Coding",
      icon: "medical_services",
    },
  },
  {
    path: "/documents",
    isPrivate: true,
    exact: true,
    component: <AzureDocuments />,
    tab: {
      label: "Coded Documents",
      icon: "medical_information",
    },
  },
  {
    isSubHeader: true,
    title: "Read and Code",
  },
  {
    path: "/submit-read-code",
    isPrivate: true,
    exact: true,
    component: <SubmitReadAndCode />,
    tab: {
      label: "Start Coding",
      icon: "import_contacts",
    },
  },
  {
    path: "/read-code",
    isPrivate: true,
    exact: true,
    component: <ReadAndCode />,
    tab: {
      label: "Coded Documents",
      icon: "auto_stories",
    },
  },
  {
    isSubHeader: true,
    title: "XML Validation",
    subTitle: "(in testing)",
  },
  {
    path: "/upload/xml",
    isPrivate: true,
    exact: true,
    component: <UploadXML />,
    tab: {
      label: "Validate XML",
      icon: "fact_check",
    },
  },
  {
    path: "/submissions",
    isPrivate: true,
    exact: true,
    component: <PreviousUploads />,
    tab: {
      label: "Previous Uploads",
      icon: "event_repeat",
    },
  },
  {
    isSubHeader: true,
    title: "DRG Advisor",
    subTitle: "(in testing)",
  },
];
