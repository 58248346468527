import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import ReadAndCodeDialog from "components/ReadAndCodeDialog/ReadAndCodeDialog";
import { isEmpty } from "lodash";
import { useState } from "react";
import snackbar from "utils/snackbar";

function ReadAndCode({ userDocument, fetchDocument }) {
  const [text, setText] = useState("");
  const [beginOffset, setBeginOffset] = useState(0);
  const [open, setOpen] = useState(false);

  const handleSelection = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0).cloneRange();
    let start = range.startOffset,
      end = range.endOffset,
      textSelected = selection.toString();
    if (textSelected.length === 0) {
      start = 0;
      end = 0;
      textSelected = "";
    } else {
      let invalidEntity = userDocument?.entities?.find(
        (e) =>
          (start <= e.offset && end >= e.offset) ||
          (start <= e.offset + e.length && start >= e.offset)
      );
      if (!isEmpty(invalidEntity)) {
        if (window.getSelection) {
          if (window.getSelection().empty) {
            // Chrome
            window.getSelection().empty();
          } else if (window.getSelection().removeAllRanges) {
            // Firefox
            window.getSelection().removeAllRanges();
          }
        } else if (document.selection) {
          // IE?
          document.selection.empty();
        }
        start = 0;
        end = 0;
        textSelected = "";
        snackbar.error(
          `Overlapping with Analyzed Entity "${invalidEntity.text}" as ${invalidEntity.kind}`
        );
      } else {
        setOpen(true);
      }
    }

    setText(textSelected);
    setBeginOffset(start);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "90%",
        justifyContent: "center",
        alignItems: "center",
        mb: 5,
      }}
    >
      <Typography
        component="div"
        variant="body1"
        sx={{
          border: "1px solid #ddd",
          p: 3,
          mb: 1,
          textAlign: "center",
          borderRadius: "5px",
          lineHeight: "2.5rem",
          width: "100%",
        }}
        onMouseUp={handleSelection}
      >
        <p id="uinq-par-1">{userDocument?.text}</p>
      </Typography>
      <ReadAndCodeDialog
        beginOffset={beginOffset}
        fetchDocument={fetchDocument}
        open={open}
        setOpen={setOpen}
        text={text}
        userDocument={userDocument}
      />
    </Box>
  );
}

export default ReadAndCode;
