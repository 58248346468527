import api from "utils/api";

export const validateSelectedCodes = async (codes, documentId) => {
  return await api
    .post("/validate", { codes, documentId })
    .then((res) => {
      const {
        data: {
          data: { errorMessages },
        },
      } = res;
      return errorMessages;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const validateXML = async (formData, setLoading) => {
  try {
    setLoading(true);
    const response = await api.post("/validate/xml", formData);
    setLoading(false);
    return response.data;
  } catch (error) {
    setLoading(false);
    console.error("Network error:", error);
  }
};

export const getUserSubmissions = async () => {
  try {
    const response = await api.get("/submissions");
    return response.data || [];
  } catch (error) {
    console.error("Network error:", error);
  }
};

export const getSubmissionsResult = async (id) => {
  try {
    const response = await api.get(`/submissions/${id}/result`);
    return response.data || [];
  } catch (error) {
    console.error("Network error:", error);
  }
};

export const getSubmissionsFile = async (id, setOpen) => {
  try {
    const response = await api.get(`/submissions/${id}/file`, {
      responseType: "blob",
    });
    const filename = response.headers["file-name"];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", filename);
    a.click();
  } catch (error) {
    console.error("Network error:", error);
    setOpen(true);
  }
};
